*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    --mainColor:rgb(255, 255, 255);
    --mainColorLight: rgb(255, 235, 213);
	--secondaryColor: #73736f68;
	--textColor: #000000;
}

header {
	display: block;
	align-items: center;
	justify-content: space-between;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
	box-shadow: 0px 2px  10px 1px var(--secondaryColor);

}

.titleText{
	padding: 10px;
	text-align: center;
	display: block;
	margin: auto;
	line-height: 1.6;
	
}
nav{
	margin: auto;
	text-align: center;
	align-items: center;
	justify-content: center;
}
nav a {
	margin: auto;
	color: var(--textColor);
	text-decoration: none;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 300px) {
	nav a {
		font-size: 0.5rem;
	}
}